.create-job-page {
  a {
    display: inline-block;
  }

  h1 {
    border-bottom: 1px solid black;
    padding-bottom: 0.25em;
    white-space: nowrap;
    width: min-content;
  }

  input {
    display: block;
    margin-bottom: 1em;
  }
}
