.icon-wrapper {
  display: inline-block;
  width: min-content;
  height: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
