.job-details-page {
  svg {
    height: 1em;
  }

  .alert-bar {
    padding: 1em;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    position: relative;

    b {
      text-transform: capitalize;

      &.scheduled {
        color: #fc0303;
      }

      &.invoicing {
        color: #fcba03;
      }

      &.active {
        color: #0703fc;
      }

      &.to-priced {
        color: #03bafc;
      }

      &.completed {
        color: #20fc03;
      }
    }

    .icon-wrapper, .button-container {
      // margin-left: auto;
      position: absolute;
      right: 1em;

      button {
        margin-left: 1em;
      }
    }
  }

  section {
    padding: 0 1em 1em 1em;

    h1 {
      border-bottom: 1px solid black;
      padding-bottom: 0.25em;
      width: min-content;
      white-space: nowrap;
    }

    &.notes {
      border: 1px solid black;

      input {
        display: block;
        margin-bottom: 1em;
      }

      .field-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        input {
          display: inline-block;
          margin-bottom: 0;
        }

        .icon-wrapper {
          margin-left: 1em;
        }
      }

      .button-container {
        display: flex;
        align-items: flex-start;

        button {
          margin-right: 1em;
        }
      }
    }
  }
}
