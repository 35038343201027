.jobs-page {
  padding: 1em;

  .top-bar {
    display: flex;
    justify-content: space-between;
  }

  input {
    width: 50%;
    margin-bottom: 1em;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th {
      padding: 0.5em 1em;
      text-align: left;

      svg {
        height: 1em;
        margin-left: 1em;
      }
    }

    tr.job-box {
      cursor: pointer;
      transition: 0.3s ease;
      border: 1px solid black;

      td {
        padding: 0.5em 1em;
        margin-bottom: 1em;

        b {
          text-transform: uppercase;
        }
      }

      &:hover {
        background-color: #eee;
      }
    }
  }
}
